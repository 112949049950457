import React from 'react';
import styled from "styled-components";
import ActionButton from "../Buttons/ActionButton";
import TaskResource from "./TaskItem/TaskResource";
import WebApp from "@twa-dev/sdk";
import {useAppDispatch} from "../../hooks/hooks";
import {fetchClaimTask, sendTask, TaskProps} from "../../store/tasksSlice";

const TaskItem: React.FC<{task: TaskProps}> = ({task}) => {
    const dispatch = useAppDispatch();
    const clickHandler = () => {

        if(task.status === 'not_started' || task.status === 'pending' || task.status === 'claimed') {
            if(task.type === 'follow') {
                WebApp.openLink(task.link)
            } else if(task.type === 'invite') {
                alert('Countable tasks under construction')
            }
        }
        if(task.status === 'finished') {
            dispatch(fetchClaimTask(task));
        } else {
            dispatch(sendTask(task))
        }
    }

    return (
        <Container>
            <TaskBlock>
                <div>
                    <TaskResource resource={task.platform}/>
                </div>
                <TextBlock>
                    <Text>{task.description} {task.type === 'invite' && `${task.progress_count <= task.target_count ? task.progress_count : task.target_count} / ${task.target_count || 0}`}</Text>
                    <Scores>+{task.reward}</Scores>
                </TextBlock>
            </TaskBlock>
            <ActionButton type={task.status} width="0" height="0" clickHandler={clickHandler}>{task.type === 'follow' ? 'open' : 'claim'}</ActionButton>
        </Container>
    );
};

export default TaskItem;

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    column-gap: 12px;
    justify-content: space-between;
    padding: 16px 0;
`;
const TaskBlock = styled.div`
    display: flex;
    align-items: flex-start;
    column-gap: 12px;
`;
const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;
const Text = styled.span`
    font-size: 14px;
    font-weight: 600;
`;
const Scores = styled.span`
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    opacity: .4;
    letter-spacing: 4px;
`;
