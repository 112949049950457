import React from 'react';
import styled from "styled-components";
interface ScreenWrapperProps {
    children: React.ReactNode;
}
const ScreenWrapper: React.FC<ScreenWrapperProps> = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    );
};

export default ScreenWrapper;

const Container = styled.div`
    width: 100%;
    height: calc(100% - 98px);
    background-color: #000;
`;
