import React, {useEffect, useState} from 'react';
import ScaleWrapper from "../components/Wrappers/ScaleWrapper";
import ScreenWrapper from "../components/Wrappers/ScreenWrapper";
import Header from "../components/CodesScreen/Header";
import Body from "../components/CodesScreen/Body";
import WebApp from "@twa-dev/sdk";
import Loader from "../components/Loader";
import {fetchCodeAttempts, fetchCodes} from "../store/codesSlice";
import {initNotification} from "../store/notificationSlice";
import {useAppDispatch, useAppSelector} from "../hooks/hooks";
import AnimationBlock from "../components/CodesScreen/AnimationBlock";



const CodesScreen: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const codesStatus = useAppSelector((state) => state.codes.status)
    const codesError = useAppSelector((state) => state.codes.error)

    useEffect(() => {
        WebApp.HapticFeedback.impactOccurred('soft');
        const body = document.querySelector('body');
        body?.classList.add('codes-screen');
        return () => {
            body?.classList.remove('codes-screen');
        }
    }, []);

    useEffect(() => {
        if(codesStatus === 'idle') {
            const fetchCodesAndAttemptsAsync = async () => {
                await dispatch(fetchCodes()).then(res => {
                    dispatch(fetchCodeAttempts());
                });
            }
            fetchCodesAndAttemptsAsync();
        }  else if(['fulfilled','fulfilled_max','fulfilled_incorrect','fulfilled_correct'].includes(codesStatus)) {
            setLoading(false)
        } else if(codesStatus === 'rejected') {
            dispatch(initNotification({data: {message: codesError, type:'error',show: true}}))
        }
    }, [dispatch, codesError, codesStatus]);

    return (
        <ScreenWrapper>
            <Loader loading={loading} duration={300}>

                <AnimationBlock/>
                {/*<ScaleWrapper style={headerStyle} height={`27vh`}>*/}
                {/*    <Header/>*/}
                {/*</ScaleWrapper>*/}

                <ScaleWrapper height="100%">
                    <Body/>
                </ScaleWrapper>
            </Loader>
        </ScreenWrapper>
    );
};

export default CodesScreen;
