import React, {useEffect, useRef, useState} from 'react';
import InputCodeArrowIcon from "../Images/InputCodeArrowIcon";
import ActionButton from "../Buttons/ActionButton";
import styled from "styled-components";
import {getClaim, sendCode} from "../../store/codesSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import WebApp from "@twa-dev/sdk";

const FormBlock: React.FC = () => {
    const blockRef = useRef<HTMLFormElement>(null);
    const [codeInput, setCodeInput] = useState('');
    const [type, setType] = useState('disabled')
    const dispatch = useAppDispatch();
    const slideState = useAppSelector((state) => state.slide)
    const codeStatus = useAppSelector((state) => state.codes.status)
    const handleInputClick = async () => {
        if(blockRef.current) {
            let container: HTMLElement | null;
            let timeout: number;

            if(WebApp.platform === 'android' || WebApp.platform === 'android_x') {
                container = document.querySelector('#scroll-form');
                timeout = 600;
            } else {
                container = document.querySelector('html');
                timeout = 100;
            }

            if(container) {
                // Need to wait until virtual keyboard changes viewport
                await new Promise(resolve => setTimeout(resolve, timeout));
                container.scrollTo({
                    top: blockRef.current.getBoundingClientRect().top + blockRef.current.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (input.length <= 10) {
            setCodeInput(input);
        }
    };

    useEffect(() => {
        if(codeStatus === 'fulfilled_correct') {
            WebApp.HapticFeedback.notificationOccurred('success');
        }
        if(codeStatus === 'fulfilled_incorrect' || codeStatus === 'fulfilled_max') {
            WebApp.HapticFeedback.notificationOccurred('error');
        }
    }, [codeStatus]);

    useEffect(() => {
        codeInput.length === 10 ? setType('active') : setType('disabled');
    }, [type, codeInput]);

    const submitHandler = (e: React.SyntheticEvent ) => {
        e.preventDefault();
        if(codeStatus === 'fulfilled_correct' || (slideState.completed_at && !slideState.claimed)) {
            dispatch(getClaim({day: slideState.day || 0, season: slideState.season || 0 }));
        } else {
            if(codeInput.length < 10) {
                return;
            }
            dispatch(sendCode({guess: codeInput.toUpperCase(), day: slideState.day, season: slideState.season }))
            setCodeInput('');
        }
    }

    return (
        <Container ref={blockRef}  onSubmit={submitHandler}>
            {codeStatus !== 'fulfilled_max' && !slideState.completed_at &&
                <>
                    <InputWrapper>
                        <ArrowLeft><InputCodeArrowIcon/></ArrowLeft>
                        <Input name="code" id="code" placeholder="enter code here" value={codeInput} onChange={handleInputChange} onClick={handleInputClick} type="text" spellCheck={false} minLength={10} maxLength={10} enterKeyHint="send" autoComplete="off" autoCorrect="off" />
                        <ArrowRight><InputCodeArrowIcon/></ArrowRight>
                    </InputWrapper>
                    <ActionButton width="100%" height="48px" type={type}
                                  clickHandler={submitHandler}>try</ActionButton>
                </>
            }
            {slideState.completed_at && !slideState.claimed &&
                <ActionButton width="100%" height="48px" type='finished'
                              clickHandler={submitHandler}>try</ActionButton>
            }
        </Container>
    );
};

export default FormBlock;

const Container = styled.form`
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    z-index: 1;
`;
const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`;
const Input = styled.input`
    display: block;
    min-height: 38px;
    height: 48px;
    padding: 0 40px;
    border: 1px solid #fff;
    background-color: rgba(255,255,255,.1);
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 12px;
    text-indent: 12px;
    text-align: center;
    text-transform: uppercase;
    &::placeholder {
        font-size: 16px;
        letter-spacing: 1px;
        text-indent: 1px;
        opacity:  .5;
        text-transform: initial;
    }
`;
const ArrowLeft = styled.div`
    position: absolute;
    left: 26px;
    height: 17px;
`;
const ArrowRight = styled.div`
    position: absolute;
    transform: rotate(180deg);
    right: 26px;
    height: 17px;
`;
