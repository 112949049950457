import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {claimReferrals, getReferrals} from "../utils/http";
import {AxiosError} from "axios";
import {SlideStateProps} from "./slideSlice";

export interface ReferralProps {
    id: number | null;
    telegram_username: string;
    points: number;
    referral_count: number,
    subreferral_count: number;
    total_points_from_referral: number
}
export interface ReferralsProps {
    data: ReferralProps[];
    total_points_to_claim: number;
    status: string;
    error: string | undefined
}

const initialState: ReferralsProps = {
    data: [],
    total_points_to_claim: 0,
    status: 'idle', // idle | pending | fulfilled | rejected
    error: ''
}

export const fetchReferrals = createAsyncThunk('referrals/fetchReferrals', async (_, {rejectWithValue}) => {
    try {
        const response = await getReferrals();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const fetchClaimReferrals = createAsyncThunk('referrals/fetchClaimReferrals', async (_, {rejectWithValue, getState}) => {
    try {
        const { referrals } = getState() as { referrals: ReferralsProps}
        const response = await claimReferrals();
        return {...response.data, total_points_to_claim: referrals.total_points_to_claim};
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

// const sortByKey = <T>(key: keyof T, order: string = 'asc') => (a: T, b: T) => {
//     const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
//     return order === 'asc' ? result : -result;
// };

export const tasksSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
        // referralsSortBy: (state, {payload: {key, order}}: { payload: {key:keyof ReferralProps, order:string} }) => {
        //     state.data = state.data.sort(sortByKey(key, order));
        // },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchReferrals.pending, (state) => {
                state.status = 'pending';
                state.error = '';
            })
            .addCase(fetchReferrals.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload.referrals.sort((a: {points: number}, b: {points: number}) => b.points - a.points);
                state.total_points_to_claim = action.payload.total_points_to_claim
            })
            .addCase(fetchReferrals.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(fetchClaimReferrals.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            state.total_points_to_claim = 0
        });

    }
})

// export const { referralsSortBy } = tasksSlice.actions

export default tasksSlice.reducer
