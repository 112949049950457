import React, {useEffect, useState} from 'react';
import 'swiper/css';
import styled from "styled-components";
import {CodeProps} from "../../store/codesSlice";
import {GetFormattedTime, PadDayZero} from "../../utils/date-utils";
import { useSwiperSlide } from 'swiper/react';
import {useAppDispatch} from "../../hooks/hooks";
import {setSlideState} from "../../store/slideSlice";
import CopyIcon from "../Images/CopyIcon";
import {initNotification} from "../../store/notificationSlice";

interface SliderItemProps {
    code: CodeProps;
}
interface CharProps {
    character: string;
    season: number;
    day: number;
    swiped_at: string | null;
}
const SliderItem: React.FC<SliderItemProps> = ({code}) => {
    const dispatch = useAppDispatch();
    const swiperSlide = useSwiperSlide();
    const [fullCode, setFullCode] = useState<CharProps[]>([]);

    const copyHandler = () => {
        const copyText = `S${code.season}:D${code.day} - ${code.swiped_characters}`;
        navigator.clipboard.writeText(copyText);
        dispatch(initNotification({data: {message: 'Code copied', type: 'info', show: true}}))
    }

    useEffect(() => {
        if(swiperSlide.isActive) {
            dispatch(setSlideState({day: code.day, season: code.season, reward: code.reward, completed_at: code.completed_at, claimed: code.claimed}));
        }
    }, [swiperSlide]);

    useEffect(() => {
        const codeArr = code.swiped_characters.split('').map((char,index) => {
            let swiped_at = GetFormattedTime(code.completed_at);
            let season = code.completed_season;
            let day = code.completed_day;
            const swipedChar = code.swipes.find(swipe => swipe.character_index === index);
            if(swipedChar) {
                swiped_at = GetFormattedTime(swipedChar.swiped_at);
                season = swipedChar.season;
                day = swipedChar.day;
            }
            return {character: char, season, day, swiped_at};
        })
        setFullCode(codeArr);
    }, [code.swiped_characters]);

    return (
        <Container>
            <H4>Code: S{code.season}.{PadDayZero(code.day)}</H4>
            <ControlBtns>
                <ControlBtn onClick={copyHandler}><CopyIcon/></ControlBtn>
            </ControlBtns>
            <Ul>
                {fullCode.map((char, index) => (
                    char.character === "_" ?
                        <Li key={index}><Letter>{char.character}</Letter></Li>
                        :
                        <Li key={index}><Letter>{char.character}</Letter><Date>S{char.season}:{char.day}D:{char.swiped_at}</Date></Li>
                ))}
            </Ul>
        </Container>
    );
};

export default SliderItem;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px 0 24px;
    background-color: transparent;
    border: 2px solid rgba(255,2555,255,.2);
    `;
const H4 = styled.h4`
    display: flex;
    position: absolute;
    top: -10px;
    left: 16px;
    margin: 0;
    padding: 0 12px;
    background-color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
`;
const ControlBtns = styled.div`
    display: flex;
    position: absolute;
    top: -20px;
    right: 16px;
    margin: 0;
    padding: 0;
    background-color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
`;
const ControlBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 2px solid rgba(255,2555,255,.2);
    cursor: pointer;
`;
const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
`;
const Li = styled.li`
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    align-items: center;
    width: 32px;
    min-height: 180px;
    row-gap: 16px;
`;
const Letter = styled.span`
    font-family: ZenDots,sans-serif;
    font-size: 24px; 
`;
const Date = styled.span`
    font-size: 13px;
    opacity: .7;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
`;
