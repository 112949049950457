import React from 'react';

const InfinityIcon = () => {
    return (
        <svg width="16" height="38" viewBox="0 0 16 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.96578 34.5234L6.74073 35.389L7.96578 37.1228L9.19082 35.389L7.96578 34.5234ZM2.48145 26.7617L1.2564 25.8961L0.644778 26.7617L1.2564 27.6273L2.48145 26.7617ZM13.4501 26.7617L14.6752 27.6273L15.2868 26.7617L14.6752 25.8961L13.4501 26.7617ZM7.96578 19L9.19082 18.1344L7.96578 16.4006L6.74073 18.1344L7.96578 19ZM7.96578 18.9999L6.74073 19.8655L7.96578 21.5993L9.19082 19.8655L7.96578 18.9999ZM2.48145 11.2382L1.2564 10.3726L0.644778 11.2382L1.2564 12.1038L2.48145 11.2382ZM13.4501 11.2382L14.6752 12.1038L15.2868 11.2382L14.6752 10.3726L13.4501 11.2382ZM7.96578 3.47648L9.19082 2.61088L7.96578 0.877125L6.74073 2.61088L7.96578 3.47648ZM9.19082 33.6578L3.70649 25.8961L1.2564 27.6273L6.74073 35.389L9.19082 33.6578ZM12.2251 25.8961L6.74073 33.6578L9.19082 35.389L14.6752 27.6273L12.2251 25.8961ZM6.74073 19.8656L12.2251 27.6273L14.6752 25.8961L9.19082 18.1344L6.74073 19.8656ZM3.70649 27.6273L9.19082 19.8656L6.74073 18.1344L1.2564 25.8961L3.70649 27.6273ZM9.19082 18.1343L3.70649 10.3726L1.2564 12.1038L6.74073 19.8655L9.19082 18.1343ZM12.2251 10.3726L6.74073 18.1343L9.19082 19.8655L14.6752 12.1038L12.2251 10.3726ZM6.74073 4.34208L12.2251 12.1038L14.6752 10.3726L9.19082 2.61088L6.74073 4.34208ZM3.70649 12.1038L9.19082 4.34208L6.74073 2.61088L1.2564 10.3726L3.70649 12.1038Z"
                fill="white"/>
        </svg>
    );
};

export default InfinityIcon;
