import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ReferralItem from "./ReferralItem";
import SortArrowDown from "../Images/SortArrowDown";
import InviteButton from "../Buttons/InviteButton";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {initNotification} from "../../store/notificationSlice";
import {fetchReferrals, ReferralProps} from "../../store/referralsSlice";
import Loader from "../Loader";

const Body = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    // const [sortReferrals, setSortReferrals] = useState('asc');
    // const [sortClaimed, setSortClaimed] = useState('asc');
    const referralsData = useAppSelector((state) => state.referrals)
    const referralsStatus = useAppSelector((state) => state.referrals.status)
    const referralsError = useAppSelector((state) => state.referrals.error)

    useEffect(() => {
        if(referralsStatus === 'idle') {
            dispatch(fetchReferrals())
        } else if(referralsStatus === 'fulfilled') {
            setLoading(false)
        } else if(referralsStatus === 'rejected') {
            dispatch(initNotification({data: {message: referralsError, type:'error',show: true}}))
        }
    }, [referralsStatus]);

    // const sortHandler = (sort: { key: keyof ReferralProps; order: string }) => {
    //     dispatch(referralsSortBy(sort))
    //     if(sort.order === 'asc') {
    //         if(sort.key === 'referral_count') {
    //             setSortReferrals('desc')
    //         } else {
    //             setSortClaimed('desc')
    //         }
    //     } else {
    //         if(sort.key === 'referral_count') {
    //             setSortReferrals('asc')
    //         } else {
    //             setSortClaimed('asc')
    //         }
    //     }
    // }

    return (
        <Container>
            {/*<FilterWrapper>*/}
            {/*    <FilterItem onClick={() => sortHandler({key: 'referral_count', order:sortReferrals})}>*/}
            {/*        <span>invited</span>*/}
            {/*        {sortReferrals &&  <SortWrapper order={sortReferrals}><SortArrowDown/></SortWrapper>}*/}
            {/*    </FilterItem>*/}
            {/*    <FilterItem onClick={() => sortHandler({key: 'points', order:sortClaimed})}>*/}
            {/*        <span>earned</span>*/}
            {/*        {sortClaimed && <SortWrapper order={sortClaimed}><SortArrowDown/></SortWrapper> }*/}
            {/*    </FilterItem>*/}
            {/*</FilterWrapper>*/}
            <UlWrapper>
            <Loader loading={loading} duration={300}>
                    <Ul>
                        {referralsData.data && referralsData.data.map(referral => <Li key={referral.id}><ReferralItem referral={referral}/></Li>)}
                    </Ul>
                </Loader>
            </UlWrapper>
            <ButtonWrapper>
                <InviteButton/>
            </ButtonWrapper>
        </Container>
    );
};

export default Body;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
// const FilterWrapper = styled.div`
//     height: 48px;
//     display: flex;
//     align-items: center;
//     column-gap: 24px;
//     justify-content: end;
//     padding: 0 36px;
// `;
// const FilterItem = styled.div`
//     display: flex;
//     align-items: center;
//     column-gap: 12px;
//     font-size: 14px;
//     font-weight: 800;
// `;
const UlWrapper = styled.div`
    height: 100%;
    overflow-y: scroll;
`;
const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
const Li = styled.li`
    padding: 16px 24px;
    border-bottom: 2px solid #2F2F2F;
`;
const ButtonWrapper = styled.div`
    height: 74px;
    display: flex;
    align-items: center;
    padding: 0 24px;
`;
// const SortWrapper = styled.div<{order: string}>`
//     display: flex;
//     align-items: center;
//     transform: ${({order}) => order === 'asc' ? 'rotate(180deg)' : ""};
// `
