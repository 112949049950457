import React from 'react';
import styled from "styled-components";
import CheckIcon from "../Images/CheckIcon";
import PendingCircleIcon from "../Images/PendingCircleIcon";

interface ActionButtonProps {
    children: React.ReactNode;
    width: string;
    height: string;
    type: string;
    clickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
}
const ActionButton: React.FC<ActionButtonProps> = ({type, width, height, children, clickHandler}) => {

    switch (type) {
        case 'not_started':
            return <PendingButton type="button" onClick={clickHandler} width={width} height={height}>{children}</PendingButton>
        case 'pending':
            return <PendingButton type="button" onClick={clickHandler} width={width} height={height}><PendingCircleIcon/></PendingButton>
        case 'disabled':
            return <DisabledButton type="button" width={width} height={height}>{children}</DisabledButton>
        case 'finished':
            return <Button type="button" onClick={clickHandler} width={width} height={height}>claim</Button>
        case 'claimed':
            return <FinishedButton type="button" onClick={clickHandler} width={width} height={height}><CheckIcon/></FinishedButton>
        default:
            return <Button type="button" onClick={clickHandler} width={width} height={height}>{children}</Button>
    }
};

export default ActionButton;

const Button = styled.button<{ width?: string; height?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    height: ${({height}) => height};
    min-width: 88px;
    width: ${({width}) => width};
    font-weight: 600;
    font-size: 16px;
    border: none;
    background-color: #fff;
    color: #000;
    letter-spacing: 1.6px;
`;
const PendingButton = styled(Button)`
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
`;
const DisabledButton = styled(PendingButton)`
    opacity: .3;
`;
const FinishedButton = styled(Button)`
    opacity: .3;
`;
