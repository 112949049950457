import React from 'react';
import InformationBlock from "./InformationBlock";
import FormBlock from "./FormBlock";

const CodeBlock:React.FC = () => {

    return (
        <>
            <InformationBlock/>
            <FormBlock/>
        </>
    );
};

export default CodeBlock;

