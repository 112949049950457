import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import Menu from "../components/Menu";
import styled from "styled-components";
import NotificationModal from "../components/NotificationModal";
import {useAppDispatch, useAppSelector} from "../hooks/hooks";
import {fetchStrike, fetchUser} from "../store/userSlice";
import {initNotification} from "../store/notificationSlice";
import {fetchSwipeState} from "../store/swipeSlice";
import WebApp from "@twa-dev/sdk";

const safeTelegramData = WebApp.initData;
const unsafeTelegramData = WebApp.initDataUnsafe;
const referralData = WebApp.initDataUnsafe.start_param;

const RootWrapper: React.FC = () => {

    const dispatch = useAppDispatch();
    const userStatus = useAppSelector((state) => state.user.status)
    const userError = useAppSelector((state) => state.user.error)
    const swipeStateStatus = useAppSelector((state) => state.swipe.status)
    const swipeStateError = useAppSelector((state) => state.swipe.error)

    useEffect(() => {
        if(userStatus === 'idle') {
            dispatch(fetchUser(
                {
                    data: safeTelegramData ,
                    invitation_code: referralData || ''
                }));
        }
        if(userStatus === 'rejected') {
            dispatch(initNotification({data: {message: userError, type:'error',show: true}}))
        }
        if(userStatus === 'fulfilled') {
            dispatch(fetchStrike());
        }
        if(userStatus === 'fulfilled_strike') {
            if (swipeStateStatus === 'idle') {
                dispatch(fetchSwipeState());
            }
            if (swipeStateStatus === 'rejected') {
                dispatch(initNotification({data: {message: swipeStateError, type: 'error', show: true}}))
            }
        }
    }, [userStatus, swipeStateStatus]);

    return (
        <Container>
            <NotificationModal/>
            <Outlet />
            <Menu/>
        </Container>
    );
};

export default RootWrapper;

const Container = styled.div`
    height: 100%;
    position: relative;
`;
