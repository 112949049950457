import React from 'react';
import Slider from "./Slider";
import styled from "styled-components";
import CodeBlock from "./CodeBlock";

const Body: React.FC = () => {

    return (
        <Container id="scroll-form">
            <H2>Codes</H2>
            <Slider/>
            <CodeBlock/>
        </Container>
    );
};

export default Body;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow-y: scroll;
`;
const H2 = styled.h2`
    padding: 0 24px;
    font-size: 3.8vh; //32px;
    font-weight: 700;
    margin: 24px 0 24px;
    opacity: .7;
`;
