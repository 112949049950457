import React from 'react';
import {Link, useLocation} from "react-router-dom";
import MenuMainIcon from "./Images/MenuMainIcon";
import MenuTasksIcon from "./Images/MenuTasksIcon";
import MenuCodesIcon from "./Images/MenuCodesIcon";
import MenuReferralsIcon from "./Images/MenuReferralsIcon";
import styled from "styled-components";

const Menu = () => {
    const { pathname } = useLocation();

    return (
        <UlWrapper>
            <Ul>
                <li><Link to={`main`}><Span active={pathname === '/main' || pathname === '/'}><MenuMainIcon/></Span></Link></li>
                <li><Link to={`tasks`}><Span active={pathname === '/tasks'}><MenuTasksIcon/></Span></Link></li>
                <li><Link to={`referral`}><Span active={pathname === '/referral'}><MenuReferralsIcon/></Span></Link></li>
                <li><Link to={`codes`}><Span active={pathname === '/codes'}><MenuCodesIcon/></Span></Link></li>
            </Ul>
        </UlWrapper>
    );
};

export default Menu;

const Ul = styled.ul`
  list-style: none;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    margin: 24px 51px;
    position: relative;
`;

const UlWrapper = styled.div`
    display: block;
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    background-color: #000;
    z-index: 90
`;

const Span = styled.span<{ active: boolean; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    border: ${({active}) => active ? '2px solid #fff' : '2px solid transparent'}
`;
// background-color: ${({active}) => active ? 'transparent' : 'rgba(255,255,255,.2)'} ; // icon background
