import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from "styled-components";
import {useSwipeable} from "react-swipeable";
import {sendSwipeState, setCanSwipe} from "../../store/swipeSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchCodes} from "../../store/codesSlice";
import WebApp from "@twa-dev/sdk";

const config = {
    delta: 0,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
}

interface SwipeElementProps {
    animationHeight: number;
    character: string;
}

const SwipeElement: React.FC<SwipeElementProps> = ({animationHeight, character}) => {
    const swipeStatus = useAppSelector(state => state.swipe.status);
    const dispatch = useAppDispatch();
    const letterRef = useRef<HTMLHeadingElement>(null)
    const [road, setRoad] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const handlers = useSwipeable({
        onSwiping: eventData => {handleSwiping(eventData)},
        onSwipedUp: eventData => {handleSwipeEnd(eventData)},
        ...config,
    });

    useEffect(() => {
        if(swipeStatus === 'fulfilled_send') {
            dispatch(fetchCodes())
        }
    }, [dispatch,swipeStatus]);

    useEffect(() => {
        if(letterRef.current) {
            // setRoad(letterRef.current.offsetTop + (letterRef.current.clientHeight / 2) - animationHeight)
            setRoad((letterRef.current.offsetTop + 240 ) + ((letterRef.current.clientHeight - 240 ) / 2) - animationHeight)
        }
    }, [animationHeight]);

    const handleSwipeEnd = (eventData: {deltaY: number}) => {
        if(eventData.deltaY <= -road) {
            dispatch(setCanSwipe(false))
            setTranslateY(0)
            dispatch(sendSwipeState());
            WebApp.HapticFeedback.notificationOccurred('success');
        } else {
            setTranslateY(0)
        }
    }
    const handleSwiping = (eventData: {absY:number,deltaY: number, initial: number[]}) => {
        if(eventData.deltaY <= 0 && eventData.deltaY >= -(road)) {
            setTranslateY(eventData.deltaY)
        }
    }

    const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
        const letter = e.target as any;
        letter.style.transition = `all 0.2s linear`;
        letter.style.transform = `translateY(-${road * 0.6}px) scale(0)`;
        letter.style.opacity = `0`;
        await new Promise(resolve => setTimeout(resolve, 200));
        dispatch(setCanSwipe(false))
        dispatch(sendSwipeState());
        WebApp.HapticFeedback.notificationOccurred('success');
    }

    return (
        <div ref={letterRef}>
            <AnimationWrapper>
                <Letter onClick={handleClick} translateY={translateY} road={road} {...handlers}>
                    {character}
                </Letter>
            </AnimationWrapper>
        </div>
    );
};

export default SwipeElement;

const LetterBump = keyframes`
    0% { transform: scale(1, 1); }
    5% { transform: scale(0.9, 1.1); }
    10% { transform: scale(1.1, 0.9); }
    15% { transform: scale(0.95, 1.05); }
    20% { transform: scale(1, 1); }
    80% { transform: scale(1, 1); }
    85% { transform: scale(0.9, 1.1); }
    90% { transform: scale(1.1, 0.9); }
    95% { transform: scale(0.95, 1.05); }
    100% { transform: scale(1,1); }
`;
const AnimationWrapper = styled.div`
    animation-name: ${LetterBump};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
`;
const Letter = styled.h1<{ translateY: number, road:number }>`
    font-family: 'ZenDots', sans-serif;
    font-size: 144px;
    margin: 12px 0;
    padding-top: 240px;
    opacity: ${({translateY, road}) => 1 + (translateY * 100 / road) * 0.01 + 0.1};
    transform: translateY(${({translateY}) => translateY * 0.6}px) scale(${({translateY, road}) => 1 + (translateY * 100 / road) * 0.01 + 0.1});
`;
