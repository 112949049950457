export const PadZero = (num: number): string => String(num).padStart(2, '0');
export const PadDayZero = (num: number): string => String(num).padStart(3, '0');

export const DateToUTC = (date: Date) => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset)
}

export const GetFormattedTime = (date:string|null) => {
    if (!date) return '';
    const d = DateToUTC(new Date(Date.parse(date)));
    return PadZero(d.getHours())+":"+PadZero(d.getMinutes())+":"+PadZero(d.getSeconds());
}

export const DiffToNow = (targetDate: string): { hours: number, minutes: number, seconds: number, totalMinutes: number } => {
    const now = DateToUTC(new Date());
    const next_hint_date = DateToUTC(new Date(Date.parse(targetDate)));

    const difference = (next_hint_date.getTime() + 1000) - now.getTime();

    const hours = Math.max(Math.floor((difference / (1000 * 60 * 60)) % 24), 0);
    const minutes = Math.max(Math.floor((difference / (1000 * 60)) % 60), 0);
    const seconds = Math.max(Math.floor((difference / 1000) % 60), 0);
    const totalMinutes = (hours * 60) + minutes;

    return { hours, minutes, seconds, totalMinutes };
};
