import axiosClient from "./axios";
import {TaskProps} from "../store/tasksSlice";
import {sendCodeProps} from "../store/codesSlice";


export const getUser = async (telegramData: {data:string, invitation_code:string}) => {

    // @TODO remove on production
    await new Promise((resolve, reject) => {setTimeout(resolve, 300);});
    return await axiosClient.post(`players/register/`, telegramData);
}

export const getToken = async (refresh_token: string | null) => {
    return await axiosClient.post(`players/refresh/`, {refresh: refresh_token});
}

export const getStrike = async () => {
    return await axiosClient.get(`strikes/register/`);
}

export const getSwipeState = async () => {
    return await axiosClient.get(`codes/hint/`);
}

export const setSwipe = async () => {
    return await axiosClient.get(`codes/swipe/`)
}

export const getTasks = async () => {
    return await axiosClient.get(`tasks/progress/`);
}

export const setTask = async (taskData: TaskProps) => {
    return await axiosClient.post(`tasks/start/`, {task_id: taskData.task_id})
}

export const claimTask = async (taskData: TaskProps) => {
    return await axiosClient.post(`tasks/claim/`, {task_id: taskData.task_id})
}

export const getReferrals = async () => {
    return await axiosClient.get(`players/referrals/`);
}

export const getCodes = async () => {
    return await axiosClient.get(`codes/progress/`);
}

export const getCodeAttempts = async () => {
    return await axiosClient.get(`codes/attempt/`);
}

export const setCode = async (codeData: sendCodeProps) => {
    return await axiosClient.post(`codes/guess/`, codeData);
}

export const claimCode = async (codeData: sendCodeProps) => {
    return await axiosClient.post(`codes/claim/`, codeData);
}

export const claimReferrals = async () => {
    return await axiosClient.get(`players/claim/`);
}
