import React, {useState} from 'react';
import styled from "styled-components";
import ReferralCard from "./ReferralCard";
import ActionButton from "../Buttons/ActionButton";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {fetchClaimReferrals} from "../../store/referralsSlice";
import CountdownTimer from "./CountdownTimer";

const futureTimestamp = Date.now() + 15 * 60 * 1000;

const Header = () => {
    const [timer, setTimer] = useState<number>(0);
    const dispatch = useAppDispatch();
    const userData = useAppSelector(state => state.user.data);
    const referralsData = useAppSelector((state) => state.referrals)

    const clickHandler = () => {
        dispatch(fetchClaimReferrals());
        setTimer(futureTimestamp - Date.now());
    }

    return (
        <>
            <Container>
                <H2>Witnesses</H2>

                <ReferralCard userCount={userData.referral_count}>
                    <CardSpan>11%</CardSpan> <CardText>of witnesses will sacrifice.</CardText>
                </ReferralCard>
                <ReferralCard userCount={userData.subreferral_count}>
                    <CardSpan>3%</CardSpan> <CardText>of the witness's followers will be shared</CardText>
                </ReferralCard>

            </Container>
            <Separator>
                <SepText>total</SepText>
                <SepBlock>
                    {timer > 0 ?
                    <SepTimer>claim in <CountdownTimer futureTimestamp={futureTimestamp}/></SepTimer>
                    :
                    <ActionButton type={referralsData.total_points_to_claim > 0 ? 'active' : 'disabled'} width="0" height="0" clickHandler={clickHandler}>claim</ActionButton>
                    }
                    <SepScore>{referralsData.total_points_to_claim.toLocaleString()}</SepScore>
                </SepBlock>
            </Separator>
        </>
    );
};

export default Header;

const Container = styled.div`
    padding: 24px 24px 0;
`;
const H2 = styled.h2`
    font-size: 3.8vh; //32px;
    font-weight: 700;
    margin: 0 0 24px;
    opacity: .7;
`;
const CardSpan = styled.span`
    opacity: 1;
    font-size: 1.9vh; //16px;
    font-weight: 800;
`;
const CardText = styled.span`
    font-size: 1.9vh; //16px;
    font-weight: 700;
    opacity: .7;
    margin: 0;
`;
const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2F2F2F;
    padding: 16px 24px;
    margin-top: 24px;
`;
const SepText = styled.span`
    text-transform: uppercase;
    font-size: 2.2vh; //18px;
    font-weight: 700;
`;
const SepBlock = styled.div`
    display: flex;
    align-items: center;
    column-gap: 18px;
`;
const SepTimer = styled.p`
    font-size: 1.9vh; //16px;
    letter-spacing: 1px;
    color: #fff;
`;
const SepScore = styled.span`
    font-size: 2.9vh; //24px;
    font-weight: 500;
`;
